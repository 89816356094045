const assets = {
  CLIENT_SIDE_ERRORS: {
    REQUIRED: 'Trebuie completat',
    POSITIVE_INTEGER: 'Trebuie sa fie un număr pozitiv',
    INVALID_FILE: 'INVALID_FILE'
  },
  FACETS: {
    PARENT: 'Parent',
    SELLER: 'Coordinator',
    STUDENT: 'Student',
    TEACHER: 'Teacher',
    VOLUNTEER: 'Volunteer'
  },
  GENERAL: {
    ACCEPT: 'Accept',
    ACCESS: 'Access',
    ACCESS_FLOW: 'Access flow',
    ACTIVATE: 'Activate',
    ACTIVITY: 'Activity',
    ADD: 'Add',
    ADD_BLANK_FLOW: 'Add a 10-step lesson template',
    ADD_CHILD: 'Add a child',
    ADMIN: 'Admin',
    APPLE_DETECTED: "We've detected that you're using an iOS device. Some features such as the smart assistant voice are blocked by default, please unblock the autoplay option.",
    ASSIGN: 'Assign',
    ASSIGN_EVENT: 'Assign an event',
    ASSIGN_EVENT_SUCCESS: 'Event successfully added',
    ASSOCIATED_STUDENTS: 'Associated students',
    ASSOCIATE_WITH_TEACHER: 'Do not forget to associate with your teachers in the Profile -> Teacher Associations section.',
    AVAILABLE: 'Available',
    BACK: 'Back',
    BOT_NAME: 'Ioana',
    CANCEL: 'Cancel',
    CHANGE_CODE: 'Change the code',
    CLAIM: 'Add student',
    CLOSE: 'Close',
    CONFIGURE: 'Configure',
    CONFIGURE_ACCOUNT: 'Configure account',
    CONFIRM: 'Confirmation',
    CONFIRMED: 'Confirmed?',
    CONTINUE: 'Continue',
    CONTINUE_TO_ASSOCIATIONS: 'Go to Associations',
    CONTINUE_TYPE_DONE: 'Type "ok" to proceed',
    COPY: 'Copy',
    COPY_LINK: 'Copy link',
    CORRECT: 'Correct',
    CORRECT_QUESTION: 'Correct!',
    CORRECT_QUESTION_EN: 'Correct!',
    CREATED_EVENTS: 'Created events',
    CREATED_FLOWS: 'Created lessons',
    CURRENT_FLOW: 'Current lesson',
    CUT: 'Cut',
    DELETE: 'Delete',
    DESCRIPTION: 'Description',
    DOCUMENTATION: 'Documentation',
    DOWNLOAD: 'Download',
    DOWNLOAD_NO_CONTENT: 'The form was sent empty',
    EDIT: 'Modify',
    EMPTY_DATA: 'No data',
    ERROR: 'Error',
    EVENT: 'Event',
    EXPAND: 'Expand',
    EXPORT: 'Export',
    EXPORT_IS_FETCHING: 'Export in progress...',
    EXPORT_FLOW: 'Export flow',
    EXPORT_PATH: 'Export path',
    FILTER_BY_NAME: 'Filter by user',
    FILTER_BY_FLOW: 'Filter by flow',
    FINISHED_EVENTS: 'Finished events',
    FINISHED_FLOWS: 'Finished flows',
    FINISHED_TESTS: 'Finished tests',
    FLOW: 'Flow',
    FORM_FIELD_NO_ANSWER: 'No reply',
    GREETING: 'Good afternoon!',
    HELPER: 'Click to view content information',
    I_UNDERSTAND: 'I understand',
    IMPORT: 'Import',
    IMPORT_FLOW: 'Import flow',
    IMPORT_PATH: 'Import path',
    IN: 'in',
    INCORRECT: 'Wrong',
    INCORRECT_QUESTION: 'Wrong!',
    INCORRECT_QUESTION_EN: 'Wrong!',
    IS_DEFAULT: 'Is default',
    LETS_CONTINUE: "Let's continue",
    KEY: 'Key',
    LOADING: 'Loading...',
    LOGIN: 'Login',
    LOGIN_WITH_CODE: 'Login with code',
    LOGOUT: 'Logout',
    MAP_ERROR: 'Error loading map',
    MESSAGE: 'Message',
    MONITORING: 'Monitoring',
    NEW_VERSION_AVAILABLE: 'Nextlab has a new version!',
    NAME: 'Name',
    NEXT: "Next",
    NO: 'No',
    NO_FLUX_COVERED: 'You have not accessed any flow with this account',
    OPTION: 'Option',
    OR: 'Or',
    PASTE: 'Paste',
    PARENT: 'Parent',
    PATH: 'Path',
    POINTS: 'points',
    PREVIEW: 'Preview',
    PREVIEW_NOUN: 'Preview',
    PRINT: 'Print',
    REFRESH: 'Refresh',
    RELOAD: 'Reload the app',
    RELOAD_APP_DESCRIPTION: 'Click to sync the web application',
    RELOAD_TABLE: 'Reload the table',
    REORDER: 'Reorder',
    REJECT: 'Reject',
    REPORT_BUG: 'Report a problem',
    REPORT: 'Report',
    ROLE: 'Role',
    RULES: 'Details and regulations',
    SAVE: 'Save',
    SEARCH: 'Search',
    SEE_CALENDAR: 'see the Calendar',
    SEE_DETAILS: 'See details',
    SEE_EDITOR: 'see all flows',
    SEE_LEARNING_STATS: 'see the whole Report',
    SEE_MORE_RESULTS: 'See more results',
    SEE_RESULTS: 'see all Results',
    SEE_STUDENTS: 'see all the Students',
    SELECT: 'Select',
    SELECTED_FILE: 'Selected file',
    SELECTED_FILE_EMPTY: 'No file selected',
    SELECT_OPTION: 'Select an option',
    SELLER: 'Seller',
    SELLER_MANAGER: 'Seller Manager',
    SEND: 'Send',
    SHOW_FORM: 'Display the form',
    SHOW_FORM_INSTANCES: 'Show entries',
    SHOW_LESS: 'Show less',
    SHOW_MORE: 'Show more',
    SIGN_IN: 'Sign in',
    START: 'Start',
    STEP: 'Step',
    STUDENT: 'Student',
    SUBMISSION_CONTENT:'Submission Content',
    SUBMISSION_GRADING: 'Submission Grading',
    SUBMISSION_GRADING_ACCEPTED: "Submission grading accepted.",
    SUBMISSION_GRADING_REJECTED: "Submission sent for regrading.",
    SYNC_PATHS: 'Syncing the library',
    TEACHER: 'Teacher',
    TEMPORARY_USER: 'Temporary user',
    TESTS_TAKEN: 'Tests taken',
    TEXT: 'Text',
    THANKS: 'Thank you',
    THANKS_EN: 'Thank you!',
    TIMESTAMP: 'Date',
    TIMEZONE: 'Europe/London',
    TITLE: 'Title',
    TOTAL: 'Total',
    URL: 'URL',
    VALUE: 'Value',
    UNLOCK_FLOW_CONFIRMATION: 'You have not unlocked this flow.\nUnlocking costs 1 point.',
    UPDATE: 'Update application',
    UPLOAD: 'Upload',
    UPLOAD_COVER_IMAGE_FOR: 'Upload cover image for',
    UPLOAD_LIMIT: 'Maximum 10MB',
    USER_CLASS: 'Class',
    USE_THIS_ACCOUNT: 'Use this account',
    VALIDATION: {
      ALPHANUMERIC_CODE: 'The code can only contain letters and numbers',
      DATE: 'The field must contain a valid date',
      DATE_INTERVAL: 'The selected time interval is invalid',
      FIELD_REQUIRED: 'This field is required!',
      NO_NULL_EXAMPLES: 'No blank examples can be added',
      VALIDATION_ERROR: 'Validation error'
    },
    VIDEO: 'Video',
    VIDEO_PRESENTATION: 'Video presentation',
    VIEW: 'View',
    WELCOME_DIALOG_HEADER: 'Welcome',
    WRONG: 'Wrong',
    YES: 'Yes',
    YOUR_ACCOUNTS: 'Your accounts'
  },
  LOGIN: {
    BACK_TO_LOGIN: ' Back to login',
    CONFIRM: 'Confirmation',
    CONTINUE_AS: 'Continue as',
    CONFIRM_PASSWORD: 'Confirm password',
    CHANGE_ACCOUNT: 'Change account',
    FULLNAME_PLACEHOLDER: 'Full name',
    GO_TO_LOGIN: 'Go to the login page',
    INSERT_USERNAME: 'Insert the username',
    INSERT_PASSWORD: 'Insert the password',
    LOGIN: 'Login',
    LOGIN_DETAILED: 'Login to the application',
    NEW_PASSWORD_PLACEHOLDER: 'New password',
    NOT_YOUR_ACCOUNT: 'Not your account',
    I_ALREADY_HAVE_AN_ACCOUNT: 'I already have an account',
    PASSWORD_PLACEHOLDER: 'Password',
    PASSWORD_NO_MATCH_EXPLANATION:
      'If you do not receive the email, check the SPAM folder in your email and make sure you entered the correct address and user.',
    PASSWORD_RECOVERY_EMAIL_SENT:
      'If your email address and username are correct you will receive an email soon.',
    CODE_PLACEHOLDER: 'Access code',
    RECOVER_CHANGE: 'Change password',
    RECOVER_PASSWORD: 'Forgot password',
    RECOVER_PASSWORD_EXPLANATION:
      'Enter your username and email address to receive a password reset link in your email.',
    RECOVER_SEND: 'Send',
    RECOVER_USERS: 'Forgot username',
    RECOVER_USERS_EXPLANATION:
      'Enter your email address to receive a list of associated users.',
    SESSION_EXPIRED: 'The current session has expired',
    USERNAME_PLACEHOLDER: 'Username',
    WELCOME_BACK: 'Welcome back,',
    TEMPORARY_ACCOUNT_GROWL_SUMMARY: 'your account is temporary',
    TEMPORARY_ACCOUNT_GROWL_DESCRIPTION: 'Configure the account to make it permanent',
    USER_NOT_FOUND_FOR_EMAIL:
      'No users were found with this email address!',
    USER_RECOVERY_EMAIL_SENT:
      "The recovery email has been sent, it will arrive in a few minutes! If you can't find it, look in the SPAM folder.",
    WELCOME_MESSAGE: 'Welcome to the platform',
    WELCOME_MESSAGE_DESCRIPTION_COL_TABEL:
      'To enter this activity you will have to choose your name from the list on the left.',
    WELCOME_MESSAGE_DESCRIPTION_COL_ADD:
      'If you cannot find your name, you can add it by typing it in the box on the right that says "Name and surname".',
    WELCOME_MESSAGE_DESCRIPTION_NO_TABLE:
      'To enter this activity you will need to enter your name in the box below that says "Name and Surname"',
    WELCOME_MESSAGE_DESCRIPTION_PHONE:
      'To enter this activity you will have to choose your name from the list. If you cannot find your name, you can add it by typing it in the box that says "Name and surname".',
    NAME_AND_SURNAME: 'Name and surname',
    COMMUNITIES: 'Community',
    USE_EXISTING_ACCOUNT: 'Use existing account'
  },
  LOGIN_PROVIDER: {
    HEADER: 'Welcome back!',
    LOGIN_MESSAGE: 'Login using one of the providers below',
    CONTINUE_GOOGLE: 'Continue with Google',
    CONTINUE_APPLE: 'Continue with Apple',
    CONTINUE_FACEBOOK: 'Continue with Facebook',
    INVALID_ACCOUNT_CONTACT_SUPPORT: 'Your account is not accesible at the moment. Please contact our support service.',
    GRADER_PLACEHOLDER: 'Grader is not supported yet in the mobile app. Please access the web interface',
    ACCOUNT_NOT_USEABLE: 'This type of account is not useable in the mobile app. Please access the web interface',
    LOGOUT: 'Use another account',
  },
  REGISTER: {
    CHILD_REGISTERING_SELF: 'I am a child',
    GENERAL: {
      CHILD_NAME: 'Child name and surname',
      CONFIRM_ACCOUNT: 'Confirm email/phone',
      CONFIRM_EMAIL: 'Code received via email',
      CONFIRM_PHONE: 'Code received via SMS',
      ESTIMATED_STUDENTS_NUMBER:
        'The estimated number of students you will enroll',
      FIELD_REQUIRED: 'This field is required!',
      INSERT_EMAIL: 'Email address',
      PARENT_EMAIL: 'Parent\'s email address',
      PARENT_PHONE: 'Parent\'s phone number (e.g. +40712345678)',
      PERFORM_CONFIRM: 'Confirm',
      PERFORM_PARENT_REGISTER: 'Create a parent account',
      PERFORM_REGISTER: 'Register',
      PERFORM_STUDENT_REGISTER: 'Create a student account',
      PERFORM_TEACHER_REGISTER: 'Create a teacher account',
      REGISTER_INTENT_PARENT: 'Parent registration',
      REGISTER_INTENT_STUDENT: 'Student registration',
      REGISTER_INTENT_TEACHER: 'Teacher registration',
      SAVE_AND_ENTER_APP: 'Save and access app',
      TWO_FACTOR_AUTHENTICATION: 'Two factor authentication',
      USER_CITY: 'City',
      USER_CLASS: 'Class',
      USER_COUNTRY: 'Country',
      USER_COUNTY: 'County',
      USER_EMAIL: 'Email address',
      USER_EMAIL_INVITED: 'Email address',
      USER_NAME: 'Full name',
      USER_OLD_PASSWORD: 'Old password',
      USER_PASSWORD: 'Password',
      USER_CONFIRM_PASSWORD: 'Confirm password',
      USER_PHONE: 'Phone number (e.g. +40712345678)',
      USER_PHONE_INVITED: 'Phone number (e.g. +40712345678)',
      USER_PREFERRED_LANGUAGE: 'Preferred language',
      USER_REGISTRATION: 'Student registration',
      USER_SCHOOL: 'School',
      USER_USERNAME: 'User',
      USER_USERNAME_REGISTER: 'Username'
    },
    LOGIN: 'I want to login',
    PARENT: {
      CHILDREN: 'Children',
      MY_DATA: 'My data',
      PERFORM_CONFIRM: 'Confirmation'
    },
    PARENT_REGISTERING_CHILD: 'I am a parent',
    REGISTER_PARENT: 'I want to register a child',
    REGISTER_SELLER: 'I want to organize lessons',
    REGISTER_TRAINER: 'I want to be a trainer',
    PARENT_REGISTRATION: 'Parent registration',
    PARENT_REGISTRATION_FORM_DETAILS:
      'Create a parent account using the form below',
    TEACHER_REGISTERING_CHILD: 'I am a teacher',
    TEACHER_REGISTRATION: 'Teacher registration',
    TEACHER_REGISTRATION_FORM_DETAILS:
      'Create a parent account using the form below',
    STUDENT_REGISTRATION: 'Student registration',
    STUDENT_REGISTRATION_FORM_DETAILS:
      'Create a student account using the form below',
    TERMS_CONDITIONS: 'Terms and Conditions',
    TERMS_CONDITIONS_ONE: 'I agree with the',
    TERMS_CONDITIONS_TWO: ' of the platform',
    PRIVACY_COOKIES_ONE: 'Accept ',
    PRIVACY: 'Privacy policy ',
    COOKIES: ' Cookies',
    AND: 'and'
  },
  SUBSCRIPTIONS: {
    ALREADY_ASSIGNED_NOTE: "This subscription has already been assigned. If you want to change it, please contact support.",
    CORRECTORS_ALLOCATION: 'Correctors Allocation',
    CORRECTOR_EMAIL: "Corrector's Email",
    EMAIL_SPECIFICATION_WARNING: "If you don't specify an email, a corrector will automatically be allocated.",
    ENTER_IMAGE_LINK: "Enter image link",
    ENTER_NUMBER: "Enter number",
    EXCEEDS_QUOTA: "Milestone exceeds the maximum allowed by your subscription plan.",
    FINISH_ASSIGNATION: "Finish Assignation",
    KNOWLEDGE_MAP_SELECTION: 'Select Knowledge Map:',
    NUMBER_OF_PAGES: "Number of Pages",
    NUMERIC_FIELD: "This field must be a number",
    PAGES: "pages",
    REQUIRED_FIELD: "Specify the milestone or number of pages",
    SUBSCRIPTION_PLAN: 'plan',
    SUBSCRIPTION_PLAN_PREFIX: ' plan for ',
    SUBSCRIPTION_ASSIGNMENT: 'Subscription Assignment',
    SUBSCRIPTION_EMAIL: "Student's Email",
    SUBSCRIPTION_STATUS: 'Status',
    SUBSCRIPTION_USER: 'User',
    SUBSCRIPTIONS: 'Subscriptions',
    TROPHY_IMAGE_ALT: "Trophy Image",
    TROPHY_ROAD_DESCRIPTION: 'Description',
    TROPHY_ROAD_HEADER: 'Trophy Road',
  },
  SERVER_MESSAGES: {
    ACCEPTED: 'Success',
    CREATED: 'Created',
    ERR: 'Error',
    ERRORS: {
      ERR_ASSISTANT_CONTEXT_CHANGED: 'Invalid chat session!',
      ERR_MEDIA_ASSETS_IMAGE_WRONG_DIMENSIONS: 'Image size is incorrect',
      ERR_CLAIM_INCOMPLETE: 'You cannot associate an account without a username and email',
      ERR_CODE_INVALID: 'Activation code invalid',
      ERR_CODE_USED: 'The activation code has already been used',
      ERR_COMPETENCY_ELEMENT_DUPLICATE_CODE: 'There is already a competency element with this code',
      ERR_COMPETITION_REGISTRATION_EXISTS: 'You have already registered',
      ERR_CONCEPT_DUPLICATE_CODE: 'There is already a concept with this code',
      ERR_CONTENT_DOMAIN_DUPLICATE_CODE: 'There is already a content domain with this code',
      ERR_CONTENT_ITEM_DUPLICATE_CODE: 'There is already a content item with this code',
      ERR_DUPLICATE_ASSISTANT: 'There is already an assistent with this name',
      ERR_DUPLICATE_KNOWLEDGE_BASE: 'There is already a knowledge base with this name',
      ERR_DUPLICATE_USER: 'There is already a user with this name',
      ERR_EVENT_FINISHED: 'The event is no longer available',
      ERR_EVENT_SCHEDULED: 'The event is not in progress now',
      ERR_EXISTING_FLOW_ID_CODE: 'The ID code of the flow has already been used',
      ERR_EXISTING_PATH_ID_CODE: 'The ID code of the path has already been used',
      ERR_EXISTS: 'This name has already been used',
      ERR_EXISTS_MANY: 'One of the usernames already exists',
      ERR_FILE_UPLOAD_LIMIT_EXCEEDED: 'The selected file is too large! It should not exceed 10MB.',
      ERR_FLOW_PRIVATE: 'You are not authorized to access this flow!',
      ERR_FORBIDDEN_SUBMISSION: 'The answers could not be submitted because the flow is no longer valid',
      ERR_GENERAL_COMPETENCY_DUPLICATE_CODE: 'There is already a general competency with this code',
      ERR_INCORRECT_TOKEN: 'Try to reconnect to the app',
      ERR_INSUFFICIENT_FUNDS: 'Insufficient funds',
      ERR_INVALID_2FA_TOKEN: 'Authentication code is invalid',
      ERR_INVALID_EMAIL: 'The email address is invalid',
      ERR_INVALID_IMPORT_FLOW: 'The file you tried to import is a path not a flow',
      ERR_INVALID_IMPORT_KNOWLEDGE_MAP: 'The knowledge map cannot be imported',
      ERR_INVALID_IMPORT_PATH: 'The file you tried to import is a flow not a path',
      ERR_INVALID_PHONE: 'The phone number is invalid',
      ERR_INVALID_VALUE: 'Invalid value!',
      ERR_JSON_INVALID: 'Invalid structure!',
      ERR_KNOWLEDGE_FIELD_DUPLICATE_CODE: 'There already exists a subject with this code',
      ERR_KNOWLEDGE_MAP_DUPLICATE_CODE: 'There already exists a knowledge map with this code',
      ERR_KNOWLEDGE_MAP_VERSION_DUPLICATE_CODE: 'There is already a knowledge map version with this code',
      ERR_MATCH_TOKEN: 'Try to reconnect to the application',
      ERR_MODEL_OVERLOADED: 'The model cannot respond to the request at the moment, please try again later',
      ERR_NOT_AUTHORIZED: 'You are not authorized to do this',
      ERR_NOT_FOUND: 'Not found',
      ERR_NOT_IMPLEMENTED: 'This operation is not yet available',
      ERR_NOT_OWNED_FLOW: 'You do not have permission to perform this operation on this flow',
      ERR_NO_ASSISTANT: 'No learning assistant selected',
      ERR_NO_CLASSIFIER: 'No classifier selected',
      ERR_NO_CODE_PATH: 'The path containing this flow does not have an identification code',
      ERR_NO_CREDENTIALS: 'Your credentials are invalid',
      ERR_NO_EXISTING_PATH: 'You cannot add an already existing path',
      ERR_NO_FLOW_SELECTED: 'No flow selected',
      ERR_NO_FORM: 'No dynamic-form selected',
      ERR_NO_KNOWLEDGEBASE: 'No knowledge base selected',
      ERR_NO_LANGUAGE: 'The assistant language was not selected',
      ERR_ONLY_STUDENT_CLAIM: 'Only student accounts may be associated',
      ERR_OVER_CAPACITY: 'The number of users is larger than the maximum allowed',
      ERR_PASSWORD_RESET_TOKEN_EXPIRED: 'The reset link has expired or is not valid!',
      ERR_PHONE_LENGTH: 'The phone number must be at least 10 characters long',
      ERR_REQUIRED_DURATION: 'Duration is mandatory',
      ERR_REQUIRED_EMAIL: 'The email address is required',
      ERR_REQUIRED_LOCATION: 'Locaion is mandatory',
      ERR_REQUIRED_PHONE: 'The phone number is required',
      ERR_REQUIRED_STUDENT_CAPACITY: 'Maximum number of children is mandatory',
      ERR_SERVER_ERROR: 'Something unexpected happened on the server',
      ERR_SPECIFIC_COMPETENCY_DUPLICATE_CODE: 'There is already a specific competency with this code',
      ERR_TIME_EXPIRED: 'The time for this operation has expired',
      ERR_TOKEN_EXPIRED: 'The link has expired',
      ERR_WRONG_CURRENT_PASSWORD: 'The current password entered is incorrect'
    }
  },
  TESTRAX_V2: {
    ACTIVITY_FEED: 'Activity feed',
    CAMERA: 'Camera',
    EVENT_TYPE: {
      GRADING_REJECTED:'Grading rejected',
      SUBMISSION_UPLOADED: 'Images uploaded',
      TEST_ASSIGNED: 'Test assigned',
      TROPHY_ROAD_ITEM_COMPLETE:'New milestone achieved',
      SUBMISSION_GRADED:'Submission graded',
      SUBMISSION_SENT_FOR_REGRADING:'Submission sent for regrading'
    },
    EXTERNAL_GRADER_MESSAGE: 'This submission was graded by an external evaluator. Do you want to accept it?',
    GALLERY_TITLE: 'GALLERY',
    SELECT_IMAGES: 'Select images',
    NO_IMAGES_SELECTED: 'No images selected',
    HISTORY: 'History',
    HISTORY_EMPTY: 'You haven\'t uploaded any documents yet',
    HOME_TITLE: 'TESTRAX',
    IMAGES: 'Images',
    IMAGES_SENT_SUCCESS: 'SUCCESS',
    IMAGES_SENT: 'The images have been submitted for analysis!',
    KNOWLEDGE_MAP_REPORT: {
      HEADER: 'Knowledge map report',
      NO_DATA: 'No data',
      KNOWLEDGE_MAP: 'Knowledge map',
      CONTENT_DOMAIN: 'Content domain',
      CONTENT_ITEM: 'Content item'
    },
    NO_ACTIVITY: 'Your activity will appear here',
    NO_PICTURES: 'Press the camera icon to open the camera',
    NO_SUBSCRIPTION: 'No valid subscription was found, please contact your parent or guardian',
    NO_SUBJECTS: 'An error was encountered with your subscription, please contact your parent or guardian',
    NO_SWOT_DATA: 'More data is required for the SWOT analysis',
    NO_THUMBNAILS_AVAILABLE: 'Image thumbnails not available',
    NO_TROPHY_ROAD_ITEM_FOUND: 'No trophy road item found',
    PRESS_FOR_PICTURE: 'Tap the camera stream to save a picture to the gallery',
    SCAN_HISTORY: 'Scan history',
    SCAN_HISTORY_EXPLANATION: 'Here you can see your latest submitted scans and preview the saved images',
    SELECT_FILE: 'Select files',
    SEND: 'Send images',
    SOLVED_EXERCISES: 'Solved exercises',
    SUBJECT: 'for',
    SUBMISSION_EDITOR_TITLE: 'Send to a grader',
    SUBMISSION_GRADING_TYPE: 'Are the exercises graded?',
    SUBMISSION_GRADING_TYPES: {
      GRADED: 'Graded',
      UNGRADED: 'Ungraded'
    },
    SUBMISSION_STATUS_TYPE: { 
      CORRECT: "CORRECT",
      INCORRECT: "INCORRECT",
      PARTIALLY_CORRECT: "PARTIALLY_CORRECT",
      NOT_ATTEMPTED: "NOT_ATTEMPTED",
      UNGRADED: "UNGRADED"
    },
    SUBMISSION_TYPE: 'Upload type',
    SUBMISSION_TYPES: {
      EXERCISE_SET: 'Exercises',
      HOMEWORK: 'Homework',
      SIMULATION: 'Exam Simulation',
      STATIC_TEST: 'Test',
      USER_TEST: 'Test',
    },
    SWOT_ALL_ELEMENTS: 'Content items in order of coverage',
    SWOT_ANALYSIS: 'SWOT analysis',
    SWOT_OPPORTUNITIES: 'Opportunities',
    SWOT_STRENGTHS: 'Strengths',
    SWOT_THREATS: 'Threats',
    SWOT_WEAKNESSES: 'Weaknesses',
    SWOT_NOT_ENOUGH_DATA: 'More data is required for a complete analysis',
    TROPHY_ROAD: 'Trophy road',
    TROPHY_ROAD_ITEM_DETAILS: 'Trophy road item details',
    UNSOLVED_EXERCISES: 'Unsolved exercises',
    OBSERVATIONS: 'Observations',
  },
  TESTRAX_TICKETING: {
    ALLOCATED_TICKETS: {
      TITLE: 'Allocated Tickets',
      LOADING: 'Loading...',
      ERROR: 'Error',
      STATUS_PLACEHOLDER: 'Select Status',
      PRIORITY_PLACEHOLDER: 'Select Priority',
      STATUSES: {
        OPEN: 'Open',
        CLOSED: 'Closed',
        PENDING_CLOSURE: 'Pending Closure',
        UNALLOCATED: 'Unallocated'
      },
      PRIORITIES: {
        LOW: 'Low',
        MEDIUM: 'Medium',
        HIGH: 'High'
      },
      SUBJECT_HEADER: 'Subject',
      STATUS_HEADER: 'Status',
      PRIORITY_HEADER: 'Priority',
      TOPIC_HEADER: 'Topic',
      CREATED_AT_HEADER: 'Created At'
    },
    TESTRAX_GRADER: {
      TITLE: 'Ticket #{ticketId}',
      LOADING: 'Loading grading data...',
      ERROR: 'Error loading grading data',
      SUBMIT_BUTTON: 'Submit Grading',
      NO_GRADING_ITEMS_FOUND: 'No grading items found',
      GRADING_STATUS_OPTIONS: {
        CORRECT: 'Correct',
        INCORRECT: 'Incorrect',
        PARTIALLY_CORRECT: 'Partially correct',
        NOT_ATTEMPTED: 'Not attempted',
        UNGRADED: 'Ungraded'
      },
      ENROLLMENT: {
        HEADER: 'Grader enrollment',
        GRADING_CONFIRMATION: 'Confirm grading invitation',
        GRADING_REJECT_CONFIRMATION: 'Are you sure you want to reject the grading invitation?'
      }
    },
    ADMIN_DASHBOARD: {
      USER_MANAGEMENT: 'User Management',
      TICKET_MANAGEMENT: 'Ticket Management'
    },
    USER_MANAGEMENT: {
      EMPTY_USER_LIST: 'No graders found',
      FULLNAME: 'Full name',
      USERNAME: 'Username',
      USER_EMAIL: 'Email',
      FACETS: 'Facets',
      ENABLED: 'Enabled',
      USERS: 'Users',
      OPENED_TICKETS: 'Opened Tickets',
      ALLOCATED_TICKETS: 'Allocated Tickets',
      PASSWORD: 'Password'
    }
  }
}

export default assets
