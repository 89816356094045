/**
 * @module graderEnrollmentActions
 * @description actions for grader enrollment
 * @category actions
 */

import { SERVER } from '../config/global'
import axios from 'axios'

export const GET_GRADER_DETAILS = 'GET_GRADER_DETAILS'
export const CONFIRM_GRADING_INVITATION = 'CONFIRM_GRADING_INVITATION'

export function getGraderDetails (email) {
  return {
    type: GET_GRADER_DETAILS,
    payload: async () => await axios.get(`${SERVER}/grader-api/details/${email}`)
  }
}

export function acceptGradingInvitation (email) {
  return {
    type: CONFIRM_GRADING_INVITATION,
    payload: async () => await axios.post(`${SERVER}/grader-api/accept-invitation`, { email })
  }
}

export function rejectGradingInvitation (email) {
  return {
    type: CONFIRM_GRADING_INVITATION,
    payload: async () => await axios.post(`${SERVER}/grader-api/reject-invitation`, { email })
  }
}

export function enrollGrader (user) {
  return {
    type: CONFIRM_GRADING_INVITATION,
    payload: async () => await axios.post(`${SERVER}/grader-api/enroll-grader`, user)
  }
}
