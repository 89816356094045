import { React, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Toast } from 'primereact/toast'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { useNavigate } from 'react-router-dom'
import { loginActions } from '../../actions'
import assets from '../../assets/texts_EN_uk'
import './LoginForm.css'

const userSelector = state => state.loginUser.user
const errorSelector = state => state.loginUser.error

const LoginForm = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const toast = useRef(null)

  const user = useSelector(userSelector)
  const error = useSelector(errorSelector)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      let errorDetail = ''
      if ('response' in error) {
        errorDetail = error.response?.data?.message
      } else if ('code' in error) {
        errorDetail = error.code
      }
      toast.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERR,
        detail: assets.SERVER_MESSAGES.ERRORS[errorDetail],
        life: 3000
      })
      dispatch({ type: 'RESET_LOGIN' })
    }
  }, [error])

  useEffect(() => {
    if (user) {
      if (user.facets.includes('ticket-admin')) {
        navigate('/user-management')
      } else if (user.facets.includes('ticket-grader')) {
        navigate('/tickets')
      }
    }
  }, [user])

  const handleLogin = () => {
    if (username && password) {
      dispatch(loginActions.login(username, password))
    } else {
      toast.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERRORS.ERR_NO_CREDENTIALS,
        life: 3000
      })
    }
  }

  return (
    <div className='login-container'>
      <Card className='login-form-card'>
        <Toast icon='pi pi-exclamation-triangle' ref={toast} />
        <div className='login-form'>
          <div className=''>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon'>
                <i className='pi pi-user' />
              </span>
              <InputText
                name='username'
                onChange={evt => setUsername(evt.target.value)}
                value={username}
                placeholder={assets.LOGIN.USERNAME_PLACEHOLDER}
              />
            </div>
          </div>
          <div className=''>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon'>
                <i className='pi pi-lock' />
              </span>
              <Password
                name='password'
                feedback={false}
                onChange={evt => setPassword(evt.target.value)}
                value={password}
                placeholder={assets.LOGIN.PASSWORD_PLACEHOLDER}
              />
            </div>
          </div>
          <div className='p-offset-1 p-lg-offset-4 p-md-offset-2 p-sm-offset-1 p-col-10 p-lg-4 p-md-8 p-sm-10'>
            <Button
              label={assets.LOGIN.LOGIN}
              className='p-button-raised'
              onClick={handleLogin}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default LoginForm
