import React from 'react'
import './AppTitle.css'

const AppTitle = () => {
  return (
    <div className='title-wrapper'>
      <div className='image-container'>
        <img
          className='logo'
          src='/assets/images/updated_logo-slogan.webp'
          alt='logo'
        />
        <div className='title-container'>
          <i className='pi pi-ticket' style={{ fontSize: '2.5rem' }} />
          <h1 className='title-heading'>
            Ticketing Platform
          </h1>
        </div>
      </div>
    </div>
  )
}

export default AppTitle
