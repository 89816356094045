
/**
 * @module userActions
 * @description actions for users
 * @category actions
 */

import { SERVER } from '../config/global';
import axios from 'axios'
import store from '../stores/store';

export const GET_USERS = 'GET_USERS';
export const UPDATE_USER ='UPDATE_USER'
export const ADD_USER = 'ADD_USER'
export const GET_USER_STATISTICS = 'GET_USER_STATISTICS'

let getUsersAbortController = null;

export function getUsers (page = '', filterString = '', sortField = '', sortOrder = '') {
    const token = store.getState().loginUser.user.token;

    if (getUsersAbortController) {
      getUsersAbortController.abort();
    }

    getUsersAbortController = new AbortController();

    return {
      type: GET_USERS,
      payload: axios.get(`${SERVER}/admin-api/users?page=${page ?? 0}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`, {
        headers: { Authorization: token },
        signal: getUsersAbortController.signal, 
      }).catch((err) => {
        if (err.message !== 'canceled') {
          throw err;
        }
      }),
    };
  };


export function updateUser (userId, user, page = '', filterString = '', sortField = '', sortOrder = '') {
    const token = store.getState().loginUser.user.token

    return {
      type: UPDATE_USER,
      payload: async () => {
        await axios.put(`${SERVER}/admin-api/users/${userId}`, user, { headers: { Authorization: token } })
        const response = axios.get(`${SERVER}/admin-api/users?page=${page ?? 0}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`, { headers: { Authorization: token } })
        return response
      }
    }
}

export function addUser(user, page = '', filterString = '', sortField = '', sortOrder = '') {
  const token = store.getState().loginUser.user.token;
  return {
    type: ADD_USER,
    payload: async () => {
      await axios.post(`${SERVER}/admin-api/users`, user, { headers: { Authorization: token } });
      const response = axios.get(`${SERVER}/admin-api/users?page=${page ?? 0}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`, { headers: { Authorization: token } })
      return response;
    }
  };
}

export function getUserStats(graderId) {
  const token = store.getState().loginUser.user.token;
  return {
    type: GET_USER_STATISTICS,
    payload: axios.get(`${SERVER}/admin-api/users/${graderId}/statistics`, {
      headers: {
        Authorization: token
      }
    })
  }
}
