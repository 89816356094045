/**
 * @module ticketActions
 * @description actions for tests
 * @category actions
 */

import { SERVER } from '../config/global'
import axios from 'axios'
import store from '../stores/store'

export const GET_ALLOCATED_TICKETS = 'GET_ALLOCATED_TICKETS'
export const GET_ALLOCATED_TICKET = 'GET_ALLOCATED_TICKET'
export const GRADE_TICKET = 'GRADE_TICKET'

export function getAllocatedTickets (graderId, page = 0, pageSize = 10, filterString = '') {
  const token = store.getState().loginUser.user.token

  return {
    type: GET_ALLOCATED_TICKETS,
    payload: axios.get(`${SERVER}/ticket-api/graders/${graderId}/tickets?page=${page}&pageSize=${pageSize}&${filterString}`, {
      headers: {
        Authorization: token
      }
    })
  }
}

export function getAllocatedTicket (ticketId) {
  const token = store.getState().loginUser.user.token

  return {
    type: GET_ALLOCATED_TICKET,
    payload: axios.get(`${SERVER}/ticket-api/tickets/${ticketId}`, {
      headers: {
        Authorization: token
      }
    })
  }
}

export function gradeTicket(ticketId, gradingResults) {
  const token = store.getState().loginUser.user.token

  return {
    type: GRADE_TICKET,
    payload: axios.put(`${SERVER}/ticket-api/tickets/${ticketId}/grade`, { gradingResults },
      {
        headers: {
          Authorization: token
        }
      })
  }
}
