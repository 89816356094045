import './GraderEnrollment.css'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { loginActions, graderEnrollmentActions } from '../../actions'
import assets from '../../assets/texts_EN_uk'

import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Password } from 'primereact/password'
import { Button } from 'primereact/button'

const userSelector = state => state.loginUser.user
const graderDetailsSelector = state => state.graderEnrollment.graderDetails
const errorSelector = state => state.loginUser.error

const GraderEnrollment = () => {
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState()
  const [confirmPassword, setConfirmPassword] = useState('')
  const toast = useRef(null)

  const user = useSelector(userSelector)
  const graderDetails = useSelector(graderDetailsSelector)
  const error = useSelector(errorSelector)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const { action, inviteToken } = params

  useEffect(() => {
    if (error) {
      let errorDetail = ''
      if ('response' in error) {
        errorDetail = error.response?.data?.message
      } else if ('code' in error) {
        errorDetail = error.code
      }
      toast.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERR,
        detail: assets.SERVER_MESSAGES.ERRORS[errorDetail],
        life: 3000
      })
      dispatch({ type: 'RESET_LOGIN' })
    }
  }, [error])

  useEffect(() => {
    if (inviteToken) {
      const inviteTokenRaw = atob(inviteToken);
  
      try {
        const { email, userKnowledgeMapId } = JSON.parse(inviteTokenRaw);
        // console.warn(`Email: ${email}, UserKnowledgeMapId: ${userKnowledgeMapId}`);
        setEmail(email);
      } catch (e) {
        console.error('Failed to parse invite token:', e);
      }
    }
  }, [inviteToken]);

  useEffect(() => {
    if (email) {
      dispatch(graderEnrollmentActions.getGraderDetails(email))
    }
  }, [email])

  const handleEnrollGrader = () => {
    dispatch(graderEnrollmentActions.enrollGrader({
      username,
      password,
      email
    }))
    navigate('/login')
  }

  const handleAcceptGradingInvite = () => {
    dispatch(graderEnrollmentActions.acceptGradingInvitation(email))
    navigate('/login')
  }

  const handleRejectGradingInvite = () => {
    dispatch(graderEnrollmentActions.rejectGradingInvitation(email))
    navigate('/login')
  }

  const graderEnrollmentTemplate = () => {
    return (
      <Card className='grader-form-card' title={assets.TESTRAX_TICKETING.TESTRAX_GRADER.ENROLLMENT.HEADER}>
        <Toast icon='pi pi-exclamation-triangle' ref={toast} />
        <div className='login-form'>
          <div className=''>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon'>
                <i className='pi pi-user' />
              </span>
              <InputText
                name='username'
                onChange={evt => setUsername(evt.target.value)}
                value={username}
                placeholder={assets.LOGIN.USERNAME_PLACEHOLDER}
              />
            </div>
          </div>
          <div className=''>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon'>
                <i className='pi pi-lock' />
              </span>
              <Password
                name='password'
                feedback={false}
                onChange={evt => setPassword(evt.target.value)}
                value={password}
                placeholder={assets.REGISTER.GENERAL.USER_PASSWORD}
              />
            </div>
          </div>
          <div className=''>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon'>
                <i className='pi pi-lock' />
              </span>
              <Password
                name='password'
                feedback={false}
                onChange={evt => setConfirmPassword(evt.target.value)}
                value={confirmPassword}
                placeholder={assets.REGISTER.GENERAL.USER_CONFIRM_PASSWORD}
              />
            </div>
          </div>
          <div className='p-offset-1 p-lg-offset-4 p-md-offset-2 p-sm-offset-1 p-col-10 p-lg-4 p-md-8 p-sm-10'>
            <Button
              label={assets.REGISTER.GENERAL.PERFORM_REGISTER}
              className='p-button-raised'
              onClick={handleEnrollGrader}
            />
          </div>
        </div>
      </Card>
    )
  }

  const gradingInviteConfirmationTemplate = () => {
    return (
      <Card className='grader-form-card' title={assets.TESTRAX_TICKETING.TESTRAX_GRADER.ENROLLMENT.GRADING_CONFIRMATION}>
        <div className='grading-invitation-confirm'>
          <Button
            label={assets.GENERAL.ACCEPT}
            className='p-button-raised'
            onClick={handleAcceptGradingInvite}
          />
          <Button
            label={assets.GENERAL.REJECT}
            className='p-button-raised'
            onClick={handleRejectGradingInvite}
          />
        </div>
      </Card>
    )
  }

  const gradingInviteRejectConfirmationTemplate = () => {
    return (
      <Card className='grader-form-card' title={assets.TESTRAX_TICKETING.TESTRAX_GRADER.ENROLLMENT.GRADING_REJECT_CONFIRMATION}>
        <div className='grading-invitation-confirm'>
          <Button
            label={assets.GENERAL.CONFIRM}
            className='p-button-raised'
            onClick={handleRejectGradingInvite}
          />
        </div>
      </Card>
    )
  }

  return (
    <div className='login-container'>
      {action === 'accept' &&
        <>
          {graderDetails && graderDetails.graderStatus === 'GRADER_REGISTERED' ? gradingInviteConfirmationTemplate() : graderEnrollmentTemplate()}
        </>}
      {action === 'reject' && gradingInviteRejectConfirmationTemplate()}
    </div>
  )
}

export default GraderEnrollment
