const INITIAL_STATE = {
    statistics: {},
    error: null,
    fetching: false,
    fetched: false
  }
  
  const userStatisticsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'GET_USER_STATISTICS_PENDING':
        return {
          ...state,
          fetching: true,
          fetched: false,
          error: null
        }
      case 'GET_USER_STATISTICS_FULFILLED':
        return {
          ...state,
          statistics: action.payload.data.statistics,
          error: null,
          fetching: false,
          fetched: true
        }
      case 'GET_USER_STATISTICS_REJECTED':
        return {
          ...state,
          error: action.payload.response.data,
          fetching: false,
          fetched: false
        }
  
      case 'RESET':
        return INITIAL_STATE
      default:
        break
    }
    return state
  }
  
  export default userStatisticsReducer
  