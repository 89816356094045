import React, { useRef } from 'react';
import './Sidebar.css';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import { loginActions, stateActions } from '../../actions';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const userMenu = useRef(null);
  const { user } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(loginActions.logout());
    dispatch(stateActions.resetState());
    navigate('/');
  };

  return (
    <div className="sidebar-container">
      {user && (
        <div
          className='user-details'
          id='user-details'
          onClick={(e) => userMenu.current.toggle(e)}
        >
          <div className='user-icon'>
            <Avatar icon="pi pi-user" className="p-avatar-icon" style={{ backgroundColor: 'transparent', color: 'black' }} />
          </div>
          <div className='name-container'>
            {`${user.username} (${user.fullName})`}
          </div>

          <Menu
            className='user-menu'
            popup
            appendTo={document.getElementById('user-details')}
            ref={userMenu}
            model={[
              {
                label: 'Logout',
                icon: 'pi pi-power-off',
                command: () => {
                  handleLogout();
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
