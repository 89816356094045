const INITIAL_STATE = {
  ticket: {},
  error: null,
  fetching: false,
  fetched: false
}

const ticketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_ALLOCATED_TICKET_PENDING':
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      }
    case 'GET_ALLOCATED_TICKET_FULFILLED':
      return {
        ...state,
        ticket: action.payload.data.ticket,
        error: null,
        fetching: false,
        fetched: true
      }
    case 'GET_ALLOCATED_TICKET_REJECTED':
      return {
        ...state,
        error: action.payload.response.data,
        fetching: false,
        fetched: false
      }

    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }
  return state
}

export default ticketReducer
