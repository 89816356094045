import { combineReducers } from 'redux'

import allocatedTickets from './allocated-tickets-reducer'
import loginUser from './login-user-reducer'
import ticket from './ticket-reducer'
import graderEnrollment from './grader-enrollment-reducer'
import user from './user-reducer'
import userStatistics from './user-statistics-reducer'

export default combineReducers({
  allocatedTickets,
  loginUser,
  ticket,
  graderEnrollment,
  user,
  userStatistics
})
