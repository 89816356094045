import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App'
import store from './stores/store'
import { Provider } from 'react-redux'
import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import NxLightbox from './webcomponents/NxLightbox'

window.customElements.define('nx-lightbox', NxLightbox)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Provider store={store}><App /></Provider>)
