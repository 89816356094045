import './TestraxGrader.css';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Checkbox } from 'primereact/checkbox';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ticketActions } from '../../actions';
import AuthorizedImage from './AuthorizedImage';
import assets from '../../assets/texts_EN_uk';

const TestraxGrader = () => {
  const { ticketId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gradingResults, setGradingResults] = useState(null);

  useEffect(() => {
    dispatch(ticketActions.getAllocatedTicket(ticketId));
  }, [dispatch, ticketId]);

  const { ticket } = useSelector((state) => state.ticket);

  useEffect(() => {
    if (ticket?.gradingResults) {
      setGradingResults(ticket.gradingResults);
    }
  }, [ticket]);

  const gradingItemStatusOptions = [
    { label: assets.TESTRAX_TICKETING.TESTRAX_GRADER.GRADING_STATUS_OPTIONS.CORRECT, value: 'CORRECT' },
    { label: assets.TESTRAX_TICKETING.TESTRAX_GRADER.GRADING_STATUS_OPTIONS.INCORRECT, value: 'INCORRECT' },
    { label: assets.TESTRAX_TICKETING.TESTRAX_GRADER.GRADING_STATUS_OPTIONS.PARTIALLY_CORRECT, value: 'PARTIALLY_CORRECT' },
    { label: assets.TESTRAX_TICKETING.TESTRAX_GRADER.GRADING_STATUS_OPTIONS.NOT_ATTEMPTED, value: 'NOT_ATTEMPTED' },
    { label: assets.TESTRAX_TICKETING.TESTRAX_GRADER.GRADING_STATUS_OPTIONS.UNGRADED, value: 'UNGRADED' }
  ];

  const addGradablePage = (imageDetails) => {
    setGradingResults((prev) => ({
      ...prev,
      gradablePages: [...prev.gradablePages, imageDetails]
    }));
  };

  const removeGradablePage = (imageDetails) => {
    setGradingResults((prev) => ({
      ...prev,
      gradablePages: prev.gradablePages.filter((page) => page.uuid !== imageDetails.uuid)
    }));
  };

  const handleGradingItemStatusChange = (status, gradingItem) => {
    const updatedItems = gradingResults.gradingItems.map((item) =>
      item._id === gradingItem._id ? { ...item, status } : item
    );
    setGradingResults((prev) => ({
      ...prev,
      gradingItems: updatedItems
    }));
  };

  const handleSubmitGrading = async () => {
    try {
      dispatch(ticketActions.gradeTicket(ticketId, gradingResults));
      navigate('/');
    } catch (error) {
      console.error('Failed to submit grading:', error);
      alert('Grading submission failed.');
    }
  };

  const gradingItemTemplate = (gradingItem, imageDetails) => {
    const isGradablePage = gradingResults.gradablePages.some((page) => page.uuid === imageDetails.uuid);
    const isDisabled = !isGradablePage || ticket.status === 'CLOSED';

    const selectedOption = gradingItemStatusOptions.find(option => option.value === gradingItem.status) || gradingItemStatusOptions.find(option => option.value === 'UNGRADED');

    return (
      <div className='grading-item-content' key={gradingItem._id}>
        <div>{`${gradingItem.itemContent.questionNumber}. ${gradingItem.itemContent.questionText}`}</div>
        <Select
          isDisabled={isDisabled}
          options={gradingItemStatusOptions}
          value={selectedOption}
          onChange={(e) => handleGradingItemStatusChange(e.value, gradingItem)}
        />
      </div>
    );
  };

  const imageTemplate = (imageDetails) => {
    const gradingItems = gradingResults.gradingItems.filter((item) => item.imageUUID === imageDetails.uuid);
    const isGradablePage = gradingResults.gradablePages.some((page) => page.uuid === imageDetails.uuid);
    return (
      <div className='grading-details-content' key={imageDetails._id}>
        <div className='grading-image-container'>
          <div className='gradable-image-checkbox'>
            <Checkbox
              checked={isGradablePage}
              onChange={(e) => (e.checked ? addGradablePage(imageDetails) : removeGradablePage(imageDetails))}
            />
          </div>
          <div className='image'>
            <AuthorizedImage
              thumbnail={`${process.env.REACT_APP_TESTRAX_SERVER}/trudesk-api/submissions/${ticket.gradingDetails.gradingSubmissionId}/thumbnails/${imageDetails.uuid}`}
              src={`${process.env.REACT_APP_TESTRAX_SERVER}/trudesk-api/submissions/${ticket.gradingDetails.gradingSubmissionId}/images/${imageDetails.uuid}`}
              authorizationToken={process.env.REACT_APP_TESTRAX_API_KEY}
            />
          </div>
        </div>
        <div className='grading-item-container'>
          {gradingItems.length > 0
            ? gradingItems.map((item) => gradingItemTemplate(item, imageDetails))
            : assets.TESTRAX_TICKETING.TESTRAX_GRADER.NO_GRADING_ITEMS_FOUND}
        </div>
      </div>
    );
  };

  return (
    <div className='testrax-grader'>
      <div className='ticket-header'>{assets.TESTRAX_TICKETING.TESTRAX_GRADER.TITLE.replace('{ticketId}', ticketId)}</div>

      <div className='testrax-grader-row'>
        <div className='grading-details-container'>
          {gradingResults?.gradingItems && ticket.gradingDetails?.images.map((imageDetails) => imageTemplate(imageDetails))}
        </div>
      </div>

      <div className='testrax-grader-action-bar'>
        <button onClick={handleSubmitGrading} className='btn submit-btn'>
          {assets.TESTRAX_TICKETING.TESTRAX_GRADER.SUBMIT_BUTTON}
        </button>
      </div>
    </div>
  );
};

export default TestraxGrader;
