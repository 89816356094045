class NxLightbox extends HTMLElement {
  static observedAttributes = ['src', 'alt', 'max-width', 'max-height', 'thumbnail']

  constructor () {
    super()
  }

  connectedCallback() {
    console.log('Custom element added to page.')
    // Create a shadow root
    const shadow = this.attachShadow({ mode: 'closed' })

    const wrapper = document.createElement('div')
    wrapper.setAttribute('class', 'wrapper')

    const source = this.getAttribute('src')
    const thumbnailImage = document.createElement('img')
    const thumbnail = this.getAttribute('thumbnail')
    thumbnailImage.setAttribute('alt', this.getAttribute('alt'))
    thumbnailImage.setAttribute('class', 'thumbnail')
    
    thumbnailImage.src = thumbnail || source

    // Create some CSS to apply to the shadow dom
    const style = document.createElement('style')

    style.textContent = `
      .wrapper {
        max-width: ${this.getAttribute('max-width') || '100%'};
        max-height: ${this.getAttribute('max-height') || '100%'};
        padding: 1rem;
      }
      
      .wrapper img.thumbnail {
        max-width: 100%;
        max-height: 100%;
      }

      .preview {
        --scroll-width: 2em;
        position: fixed;
        z-index: 10000;
        top: 0;
        left: 0;
        max-width: 100vw;
        padding: 1em;
        background-color: black;
        display: none;
      }

      .preview .toolbar {
        background-color: white;
        display: flex;
        justify-content: flex-end;         
      }

      .preview .zoom-container {
        max-width: calc(100vw - var(--scroll-width));
        max-height: 100vh;
        overflow: auto; 
        scrollbar-width: thin;
      }

      .preview .zoom-container .full-image {
        max-width: calc(100vw - var(--scroll-width));
        width: calc(100vw - var(--scroll-width));
        // max-height: 100vw;
      }

      .preview .zoom-container .full-image.zoomed-in {
        width: 200vw;
        max-width: unset;
      }

      .preview .btn {
        cursor: pointer;
        padding: 0.5em;
        width: 2em;
        font-size: 2em;
        text-align: center;
      }
    `
    const preview = document.createElement('div')
    preview.setAttribute('class', 'preview')
    const toolbar = document.createElement('div')
    toolbar.setAttribute('class', 'toolbar')
    const zoomInBtn = document.createElement('div')
    zoomInBtn.setAttribute('class', 'btn zoom-in')
    zoomInBtn.textContent = '⊕'

    toolbar.appendChild(zoomInBtn)

    const zoomOutBtn = document.createElement('div')
    zoomOutBtn.setAttribute('class', 'btn zoom-out')
    zoomOutBtn.textContent = '⊖'

    toolbar.appendChild(zoomOutBtn)
    preview.appendChild(toolbar)

    const closeBtn = document.createElement('div')
    closeBtn.setAttribute('class', 'btn close')
    closeBtn.textContent = '✖'

    closeBtn.onclick = () => {
      preview.style.display = 'none'
    }

    toolbar.appendChild(closeBtn)

    const zoomContainer = document.createElement('div')
    zoomContainer.setAttribute('class', 'zoom-container')

    const img = document.createElement('img')
    img.src = source
    img.setAttribute('class', 'full-image')

    zoomInBtn.onclick = () => {
      img.classList.add('zoomed-in')
    }

    zoomOutBtn.onclick = () => {
      img.classList.remove('zoomed-in')
    }

    zoomContainer.appendChild(img)
    preview.appendChild(zoomContainer)
    wrapper.appendChild(preview)
    
    thumbnailImage.onclick = () => {
      preview.style.display = 'block'
    }

    // Attach the created elements to the shadow dom
    shadow.appendChild(style)
    wrapper.appendChild(thumbnailImage)
    shadow.appendChild(wrapper)
  }

}

export default NxLightbox