import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ticketActions } from '../../actions';
import assets from '../../assets/texts_EN_uk';
import './AllocatedTickets.css';

const AllocatedTickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allocatedTickets, count, fetching } = useSelector((state) => state.allocatedTickets);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState('OPEN');
  const [selectedPriority, setSelectedPriority] = useState(null);

  const { user } = useSelector((state) => state.loginUser);

  const statusOptions = [
    { label: assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.STATUSES.OPEN, value: 'OPEN' },
    { label: assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.STATUSES.CLOSED, value: 'CLOSED' },
    { label: assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.STATUSES.PENDING_CLOSURE, value: 'PENDING_CLOSURE' },
    { label: assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.STATUSES.UNALLOCATED, value: 'UNALLOCATED' }
  ];

  const priorityOptions = [
    { label: assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.PRIORITIES.LOW, value: 'LOW' },
    { label: assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.PRIORITIES.MEDIUM, value: 'MEDIUM' },
    { label: assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.PRIORITIES.HIGH, value: 'HIGH' }
  ];

  const statusFilterString = selectedStatus ? `status=${selectedStatus}` : '';
  const priorityFilterString = selectedPriority ? `priority=${selectedPriority}` : '';
  const filterString = [statusFilterString, priorityFilterString].filter(Boolean).join('&');

  useEffect(() => {
    dispatch(ticketActions.getAllocatedTickets(user._id, page, pageSize, filterString));
  }, [user, page, pageSize, filterString, dispatch]);

  const onPageChange = (event) => {
    setPage(event.page + 1);
    setPageSize(event.rows);
  };

  const onRowClick = (ticket) => {
    navigate(`/tickets/${ticket._id}`);
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={selectedStatus}
        options={statusOptions}
        onChange={(e) => {
          setSelectedStatus(e.value);
          options.filterApplyCallback(e.value);
        }}
        placeholder={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.STATUS_PLACEHOLDER}
        className="p-column-filter"
        showClear
      />
    );
  };

  const priorityRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={selectedPriority}
        options={priorityOptions}
        onChange={(e) => {
          setSelectedPriority(e.value);
          options.filterApplyCallback(e.value);
        }}
        placeholder={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.PRIORITY_PLACEHOLDER}
        className="p-column-filter"
        showClear
      />
    );
  };

  const createdAtBodyTemplate = (rowData) => {
    return dayjs(rowData.createdAt).format('MMMM D, YYYY h:mm A');
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span className={`ticket-status ${rowData.status.toLowerCase()}`}>
        {rowData.status.replace('_', ' ')}
      </span>
    );
  };

  const priorityBodyTemplate = (rowData) => {
    return <span>{rowData.priority}</span>;
  };

  const gradeButtonTemplate = (rowData) => {
    return rowData.status === 'OPEN' ? (
      <Button
        label="Grade"
        icon="pi pi-pencil"
        className="grade-button"
        style={{ backgroundColor: 'var(--primary-blue)', color: 'white', fontWeight: 'bold' }}
        onClick={() => onRowClick(rowData)}
      />
    ) : (
      <Button
        label="Review Grading"
        icon="pi pi-eye"
        className="review-button"
        style={{ backgroundColor: 'var(--primary-blue)', color: 'white', fontWeight: 'bold' }}
        onClick={() => onRowClick(rowData)}
      />
    );
  };

  return (
    <div className="p-mt-4 p-px-4">
      <Card title={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.TITLE} style={{ textAlign: 'center' }}>
        {fetching ? (
          <p>{assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.LOADING}</p>
        ) : (
          <>
            <DataTable
              value={allocatedTickets}
              responsiveLayout='scroll'
              onRowClick={(e) => onRowClick(e.data)}
              paginator={false}
              filters={{
                status: { value: selectedStatus },
                priority: { value: selectedPriority }
              }}
              filterDisplay="row"
            >
              <Column field="subject" header={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.SUBJECT_HEADER} sortable />
              <Column field="status" header={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.STATUS_HEADER} sortable body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
              <Column field="priority" header={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.PRIORITY_HEADER} sortable body={priorityBodyTemplate} filter filterElement={priorityRowFilterTemplate} />
              <Column field="topic" header={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.TOPIC_HEADER} sortable />
              <Column field="createdAt" header={assets.TESTRAX_TICKETING.ALLOCATED_TICKETS.CREATED_AT_HEADER} body={createdAtBodyTemplate} sortable />
              <Column body={gradeButtonTemplate} header="Actions" />
            </DataTable>
            <Paginator
              first={(page - 1) * pageSize}
              rows={pageSize}
              totalRecords={count}
              onPageChange={onPageChange}
            />
          </>
        )}
      </Card>
    </div>
  );
};

export default AllocatedTickets;
