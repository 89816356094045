import React from 'react';

class AuthorizedImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnailContent: '',
      imageContent: '',
      isZoomOpen: false
    };
  }

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src || prevProps.thumbnail !== this.props.thumbnail) {
      this.loadImages();
    }
  }

  loadImages = () => {
    if (this.props.src && this.props.thumbnail && this.props.authorizationToken) {
      Promise.all([
        fetch(this.props.src, {
          method: 'GET',
          headers: { Authorization: this.props.authorizationToken }
        }),
        fetch(this.props.thumbnail, {
          method: 'GET',
          headers: { Authorization: this.props.authorizationToken }
        })
      ])
        .then(([imageResponse, thumbnailResponse]) => {
          if (!imageResponse.ok || !thumbnailResponse.ok) {
            throw new Error('Failed to load images');
          }
          return Promise.all([imageResponse.blob(), thumbnailResponse.blob()]);
        })
        .then(([imageBlob, thumbnailBlob]) => {
          this.setState({
            imageContent: URL.createObjectURL(imageBlob),
            thumbnailContent: URL.createObjectURL(thumbnailBlob)
          });
        })
        .catch((err) => {
          this.setState({ thumbnailContent: '', imageContent: '' });
          console.error('Image loading failed:', err);
        });
    }
  };

  render() {
    return (
      <div className="authorized-image">
        {this.state.imageContent && this.state.thumbnailContent ? (
          <nx-lightbox
            thumbnail={this.state.thumbnailContent}
            src={this.state.imageContent}
          ></nx-lightbox>
        ) : (
          'Loading...'
        )}
      </div>
    );
  }
}

export default AuthorizedImage;
