/**
 * @module loginActions
 * @description actions for login
 * @category actions
 */
import { Buffer } from 'buffer'
import { SERVER } from '../config/global'
import axios from 'axios'
import store from '../stores/store'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export function login (username, password) {
  return {
    type: LOGIN,
    payload: axios.post(
      `${SERVER}/auth-api/user`,
      {},
      {
        headers: {
          Authorization: `Basic ${Buffer.from(
            username + ':' + password
          ).toString('base64')}`
        }
      }
    )
  }
}

export function logout () {
  const { token } = store.getState().loginUser.user
  return {
    type: LOGOUT,
    payload: axios.delete(`${SERVER}/auth-api`, { headers: { Authorization: token } })
  }
}
