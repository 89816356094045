import './App.css'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import AdminDashboard from '../AdminDashboard'
import AllocatedTickets from '../AllocatedTickets/AllocatedTickets'
import AppTitle from '../AppTitle'
import GraderStats from '../GraderStats'
import LoginForm from '../Login/LoginForm'
import ProtectedRoute from '../ProtectedRoute'
import Sidebar from '../Sidebar'
import TestraxGrader from '../TestraxGrader/TestraxGrader'
import GraderEnrollment from '../GraderEnrollment'
import UserManagement from '../UserManagement/UserManagement'
import { useSelector } from 'react-redux'

function App () {
  const isAuthenticated = useSelector((state) => state.loginUser.isAuthenticated); 

  return (
    <Router>
        <div className='app-container'>
        {isAuthenticated && <Sidebar />} 
        <AppTitle />
        <Routes>
          <Route exact path='/' element={<LoginForm />} />
          <Route exact path='/login' element={<LoginForm />} />
          <Route path='/grader-enrollment/:action/:inviteToken' element={<GraderEnrollment />} />
          <Route path='/tickets' element={<ProtectedRoute element={<AllocatedTickets />} />} />
          <Route path='/tickets/:ticketId' element={<ProtectedRoute element={<TestraxGrader />} />} />
          <Route path='/user-management' element={<ProtectedRoute element={<UserManagement />} />} />
          <Route path="/users/:userId/statistics" element={<GraderStats />} />
        </Routes>
    </div>
      </Router>
  )
}

export default App
