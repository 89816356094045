import { applyMiddleware, createStore, compose } from 'redux'
import logger from 'redux-logger'
import promise from 'redux-promise-middleware'
import throttle from 'lodash/throttle'
import { loadState, saveState } from './local-storage'
// import { loadUserState, saveUserState } from './user-storage'
// import analytics from './analytics'
// import tokenGuard from './token-guard'
// import networkError from './network-error'
// import networkLocale from './network-locale'

import reducer from '../reducers'
import axios from 'axios'
import requestAbortMiddleware from './request-abort-middleware'

const middlewareList = process.env.NODE_ENV === 'development' ? [promise, requestAbortMiddleware, logger] : [promise, requestAbortMiddleware]
const middleware = applyMiddleware(...middlewareList)

const decoratedMiddleware = middleware

const persistedState = loadState()
// const persistedUserState = loadUserState(persistedState?.loginUser?.user?.username)

axios.defaults.headers.common['Accept-Language'] = 'ro-RO,ro;q=0.9'

const store = createStore(reducer, Object.assign({}, persistedState), decoratedMiddleware)

store.subscribe(
  throttle(() => {
    saveState(store.getState())
    const currentUser = store.getState().loginUser?.user?.username
    // saveUserState(currentUser, store.getState())
  }),
  1000
)

export default store
