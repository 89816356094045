import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import './UserManagement.css';
import { userActions } from '../../actions';
import UserDialog from './UserDialog';
import assets from '../../assets/texts_EN_uk';
import { useNavigate } from 'react-router-dom';

const UserManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userList = useSelector((state) => state.user.userList);
  const count = useSelector((state) => state.user.count);
  const loading = useSelector((state) => state.user.fetching);

  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState(1);
  const pageSize = 10;
  const [selectedUser, setSelectedUser] = useState(null); 
  const [isUserDialogShown, setIsUserDialogShown] = useState(false); 

  const filterKeys = Object.keys(filters);
  const filterString = filterKeys
    .map((key) => `${key}=${filters[key].value}`)
    .join('&');

  useEffect(() => {
    if (filterString.length > 0) {
      setCurrentPage(0);
    }
  }, [filterString]);

  useEffect(() => {
    dispatch(userActions.getUsers(currentPage, filterString, sortField, sortOrder));
  }, [currentPage, filterString, sortField, sortOrder, dispatch]);

  const handlePageChange = (evt) => {
    setCurrentPage(evt.page);
  };

  const handleSort = (evt) => {
    setSortField(evt.sortField);
    setSortOrder(evt.sortOrder);
  };

  const handleFilter = (evt) => {
    setFilters(evt.filters);
  };

  const handleAddNewUser = () => {
    setSelectedUser({ username: '', email: '', fullName: '', facets: [], enabled: true });
    setIsUserDialogShown(true);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsUserDialogShown(true);
  };

  const handleTicketAction = (user) => {
    navigate(`/users/${user._id}/statistics`);
  };

  const tableFooter = (
    <div className="user-management-footer">
      <Button
        label={assets.GENERAL.ADD}
        onClick={handleAddNewUser}
        icon="pi pi-plus"
        className="p-mr-1 add-button"
      />
    </div>
  );

  const editTemplate = (rowData) => (
    <div className="action-buttons">
      <Button
        icon="pi pi-ticket"
        className="p-mr-2 ticket-button"
        disabled={rowData.allocatedTickets === 0}
        onClick={() => handleTicketAction(rowData)}
      />
      <Button
        icon="pi pi-pencil"
        className="p-button-primary edit-button"
        onClick={() => handleEditUser(rowData)}
      />
    </div>
  );

  return (
    <div className="user-management">
      <h2>{assets.TESTRAX_TICKETING.USER_MANAGEMENT.USERS}</h2>
      {!loading && !count ? (
        <div className="p-mt-2 p-mb-4">
          {assets.TESTRAX_TICKETING.USER_MANAGEMENT.EMPTY_USER_LIST}
        </div>
      ) : (
        <div className="table-wrapper">
          <DataTable
            value={userList}
            paginator
            rows={10}
            responsive
            footer={tableFooter}
            onPage={handlePageChange}
            first={currentPage * pageSize}
            loading={loading}
            lazy
            totalRecords={count}
            onSort={handleSort}
            sortField={sortField}
            sortOrder={sortOrder}
            filters={filters}
            onFilter={handleFilter}
          >
            <Column field="fullName" header="Name" sortable />
            <Column field="username" header={assets.TESTRAX_TICKETING.USER_MANAGEMENT.USERNAME} />
            <Column field="email" header={assets.TESTRAX_TICKETING.USER_MANAGEMENT.USER_EMAIL} />
            <Column field="facets" header={assets.TESTRAX_TICKETING.USER_MANAGEMENT.FACETS} />
            <Column field="enabled" header={assets.TESTRAX_TICKETING.USER_MANAGEMENT.ENABLED} />
            <Column field="openedTickets" header={assets.TESTRAX_TICKETING.USER_MANAGEMENT.OPENED_TICKETS} />
            <Column field="allocatedTickets" header={assets.TESTRAX_TICKETING.USER_MANAGEMENT.ALLOCATED_TICKETS} />
            <Column body={editTemplate} header={assets.TESTRAX_TICKETING.USER_MANAGEMENT.ACTIONS} />
          </DataTable>
        </div>
      )}

      {isUserDialogShown && (
        <UserDialog
          user={selectedUser}
          onHide={() => setIsUserDialogShown(false)}
          page={currentPage}
          filterString={filterString}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      )}
    </div>
  );
};

export default UserManagement;
