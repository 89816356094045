import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import dayjs from 'dayjs'; 
import { userActions } from '../../actions';
import './GraderStats.css';

const GraderStats = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { statistics, fetching, error } = useSelector((state) => state.userStatistics);
  const { user } = useSelector((state) => state.loginUser);

  useEffect(() => {
    if (userId) {
      dispatch(userActions.getUserStats(userId));
    }
  }, [dispatch, userId]);

  const getComputedStyleValue = (property) => {
    return getComputedStyle(document.documentElement).getPropertyValue(property).trim();
  };

  const primaryBlue = getComputedStyleValue('--primary-blue');
  const primaryRed = getComputedStyleValue('--primary-red');

  const { openedTickets, allocatedTickets, monthlyAllocations } = statistics;
  const closedTickets = allocatedTickets - openedTickets;

  const pieData = {
    labels: ['Closed Tickets', 'Opened Tickets'],
    datasets: [
      {
        label: 'Tickets',
        data: [closedTickets, openedTickets],
        backgroundColor: [primaryBlue, primaryRed],
        hoverOffset: 4,
      },
    ],
  };

  const formattedLabels = monthlyAllocations?.map((item) => 
    dayjs(`${item.year}-${item.month}-01`).format('MMMM YYYY')
  );

  const lineData = {
    labels: formattedLabels,
    datasets: [
      {
        label: 'Allocated Tickets',
        data: monthlyAllocations?.map((item) => item.ticketCount),
        borderColor: primaryBlue,
        backgroundColor: 'rgba(66, 63, 219, 0.5)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div className="grader-stats-container">
      <h2 className="grader-stats-title">{user.fullName}'s Ticket Stats</h2>

      <h3 className="chart-header">Allocated Tickets Distribution</h3>
      <div className="pie-chart-section">
        <div className="chart-wrapper">
          <Pie data={pieData} options={chartOptions} />
        </div>

        <div className="ticket-summary">
          <p><strong>Allocated Tickets:</strong> {allocatedTickets}</p>
          <p><strong>Opened Tickets:</strong> {openedTickets}</p>
          <p><strong>Closed Tickets:</strong> {closedTickets}</p>
        </div>
      </div>

      <h3 className="chart-header line-chart-header">Grader's Monthly Activity</h3>
      <div className="line-chart-section">
        <div className="chart-wrapper">
          <Line data={lineData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default GraderStats;
