import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password'; 
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import Select from 'react-select';
import { useDispatch } from 'react-redux'; 
import { userActions } from '../../../actions'; 
import assets from '../../../assets/texts_EN_uk';
import './UserDialog.css'; 

const UserDialog = ({ user, onHide, page, filterString, sortField, sortOrder }) => {
  const dispatch = useDispatch(); 

  const [editedUser, setEditedUser] = useState({
    username: '',
    email: '',
    fullName: '', 
    password: '', 
    facets: [],
    enabled: true,
  });

  useEffect(() => {
    if (user) {
      setEditedUser(user);
    }
  }, [user]);

  const handleInputChange = (e, field) => {
    setEditedUser({ ...editedUser, [field]: e.target.value });
  };

  const handleFacetChange = (selectedOptions) => {
    setEditedUser({
      ...editedUser,
      facets: selectedOptions ? selectedOptions.map((option) => option.value) : [],
    });
  };

  const handleCheckboxChange = (e) => {
    setEditedUser({ ...editedUser, enabled: e.checked });
  };

  const handleSave = () => {
    if (!editedUser._id) {
      dispatch(
        userActions.addUser(
          editedUser, 
          page,
          filterString,
          sortField,
          sortOrder
        )
      );
    } else {
      dispatch(
        userActions.updateUser(
          editedUser._id,
          editedUser, 
          page,
          filterString,
          sortField,
          sortOrder
        )
      );
    }
    onHide();
  };

  const facetsOptions = [
    { label: 'ticket-admin', value: 'ticket-admin' },
    { label: 'ticket-grader', value: 'ticket-grader' },
  ];

  return (
    <Dialog
      header={!editedUser._id ? "Add User" : "Edit User"}
      visible={true}
      style={{ width: '50vw' }}
      modal
      onHide={onHide}
      className="user-dialog"
    >
      <div className="p-grid p-fluid">
        <div className="p-field p-col-12">
          <label htmlFor="username">{assets.TESTRAX_TICKETING.USER_MANAGEMENT.USERNAME}</label>
          <InputText
            id="username"
            value={editedUser.username}
            disabled = {editedUser._id}
            onChange={(e) => handleInputChange(e, 'username')}
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="fullName">{assets.TESTRAX_TICKETING.USER_MANAGEMENT.FULLNAME}</label>
          <InputText id="fullName" value={editedUser.fullName} onChange={(e) => handleInputChange(e, 'fullName')} />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="email">{assets.TESTRAX_TICKETING.USER_MANAGEMENT.USER_EMAIL}</label>
          <InputText id="email" value={editedUser.email} onChange={(e) => handleInputChange(e, 'email')} />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="password">{assets.TESTRAX_TICKETING.USER_MANAGEMENT.PASSWORD}</label>
          <Password id="password" value={editedUser.password} onChange={(e) => handleInputChange(e, 'password')} placeholder="Leave empty to keep current password" />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="facets">{assets.TESTRAX_TICKETING.USER_MANAGEMENT.FACETS}</label>
          <Select
            isMulti
            options={facetsOptions}
            value={facetsOptions.filter((option) => editedUser.facets.includes(option.value))}
            onChange={handleFacetChange}
            classNamePrefix="react-select"
          />
        </div>
        <div className="p-field-checkbox p-col-12">
          <Checkbox inputId="enabled" checked={editedUser.enabled} onChange={handleCheckboxChange} />
          <label htmlFor="enabled">{assets.TESTRAX_TICKETING.USER_MANAGEMENT.ENABLED}</label>
        </div>
      </div>
      <div className="p-dialog-footer">
        <Button label="Save" className="save-button"  onClick={handleSave} />
      </div>
    </Dialog>
  );
};

export default UserDialog;
